import { css } from "@emotion/react";
import React, { ComponentProps, useCallback, useRef, useState } from "react";
import { BuildingFormModalContainer } from "../../../../../containers/modals/BuildingFormModal";
import { useBuildingModal } from "../../../../../containers/modals/BuildingFormModal/hooks";
import { GraphQLLessorBranch } from "../../../../../graphql";
import { ApiLessorUser } from "../../../../../repository";
import { Color, Spacing, ZIndex } from "../../../../../tokens";
import DropdownCheckboxes from "../../../../uiParts/dataEntry/DropdownCheckboxes";
import Searchbox from "../../../../uiParts/dataEntry/Searchbox";
import BuildingList from "../BuildingList";
import PropertyHead from "../PropertyHead";
import { useAssigneesFilter } from "./hooks/useAssigneesFilter";
import { useBranchesFilter } from "./hooks/useBranchesFilter";
import { useDisplayBuildings } from "./hooks/useDisplayBuildings";

type Buildings = Array<
  Parameters<typeof useDisplayBuildings>[0][0] &
    ComponentProps<typeof BuildingList>["buildings"][0]
>;

type Props = {
  lessorId: number;
  lessorUserId: number;
  lessorBranchId?: number;
  buildings: Buildings;
  lessorBranches: GraphQLLessorBranch[];
  lessorUsers?: ApiLessorUser[];
};

const PropertyManagement: React.FC<Props> = ({
  buildings,
  lessorUserId,
  lessorBranchId,
  lessorBranches,
  lessorUsers,
}) => {
  // API使って検索する場合、buildingsを引いているところからやる
  const [propertySearchText, setPropertySearchText] = useState("");

  const { selectedBranches, onSelectedBranchesChange, lessorBranchItems } =
    useBranchesFilter(lessorBranches, lessorBranchId);

  const { selectedAssignees, onSelectedAssigneesChange, assigneesItems } =
    useAssigneesFilter(lessorUserId, lessorUsers);

  const { displayBuildings } = useDisplayBuildings(
    buildings,
    propertySearchText,
    selectedBranches,
    selectedAssignees,
  );

  const buildingModalSymbol = useRef(Symbol("BuildingModalSymbol"));
  const { openModal, ...buildingModalProps } = useBuildingModal(
    buildingModalSymbol.current,
  );

  const onClickEdit = useCallback(
    (buildingId: number) => {
      openModal(buildingId);
    },
    [openModal],
  );

  return (
    <>
      <PropertyHead
        onClickCreateBuilding={() => {
          openModal();
        }}
      />
      <div css={filterGroupStyle}>
        <ul css={filterItemsStyle}>
          <li css={filterItemStyle}>
            <DropdownCheckboxes
              selectedValues={selectedBranches}
              label="担当支店・部署"
              items={lessorBranchItems}
              hasSearchBox
              searchBoxPlaceholder="支店・部署名で検索する"
              onChange={onSelectedBranchesChange}
            />
          </li>
          <li css={filterItemStyle}>
            <DropdownCheckboxes
              selectedValues={selectedAssignees}
              label="担当者"
              items={assigneesItems}
              hasSearchBox
              searchBoxPlaceholder="氏名で検索する"
              onChange={onSelectedAssigneesChange}
            />
          </li>
        </ul>
        <div css={filterSearchStyle}>
          <Searchbox
            placeholder="ビル名で検索する"
            value={propertySearchText}
            onChange={setPropertySearchText}
            css={searchboxStyle}
          />
        </div>
      </div>
      <div css={propertyManagementStyle}>
        <BuildingList buildings={displayBuildings} onClickEdit={onClickEdit} />
      </div>
      <BuildingFormModalContainer {...buildingModalProps} />
    </>
  );
};

const propertyManagementStyle = css`
  height: calc(100% - 89px);
  overflow: scroll;
  background: ${Color.White};
`;

const filterGroupStyle = css`
  position: relative;
  padding: ${Spacing[8]} ${Spacing[8]} 0;
  display: flex;
  gap: ${Spacing[8]};
  z-index: ${ZIndex.Filter};
`;

const filterItemsStyle = css`
  display: flex;
  flex-shrink: 1;
`;

const filterItemStyle = css`
  max-width: 200px;
  & + & {
    margin-left: ${Spacing[8]};
  }
`;

const filterSearchStyle = css`
  flex-shrink: 1;
  margin-left: auto;
`;

const searchboxStyle = css`
  width: 240px;
`;

export default PropertyManagement;
